.whFont{
    font-family: 'Raleway-bold', serif;
    background-Color:"#83868b" !important
}

#home a:link { color: #FFFFFF !important; }
#home a:visited { color: #FFFFFF !important; }
#home a:hover { color: #FFFFFF !important; }
#home a:active { color: #FFFFFF !important; }
/* a:link
{
    color:#FFFFFF;
}

a:visited
{
    color:#FFFFFF;
}

a:hover 
{
    color:#FFFFFF !important; 
} */