.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .title{
    font-family: 'Raleway', serif;
  }

  .div-with-image-background{
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  .textContainer{
    font-weight: 600;
    border-width: 2px;
    border-radius: 5px;
    padding :10px;
    border-style:solid;
    border-color:#83868b; 
    background: rgba(225,225,225,0.5);
    font-family: 'Raleway', serif;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  }

  .footerColumn{
    font-family: 'Raleway-bold', serif;
    color: white;
  }

  .navbar {
    position: relative; 
z-index: 999;
  }

  .bg-light{
    background-Color:"#83868b"
  }

  .shadow-image{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }

  .navbar-brand{
    color:white !important
  }

  .nav-link{
    color:#e8edee !important
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
  
  .custom-toggler.navbar-toggler {
    border-color: rgb(255,255,255) !important;
  }

  .image-wh{
    margin: auto;    
    display: block;
  }
  .row-m-20{
    margin-bottom:20px
  }
 .detailsButton{
   color:black !important;
 }

 .rbc-show-more{
   color:black !important
 }

  @media only screen and (max-width: 678px) {
      .image-wh{
        max-width:100%
    }
    .navbar-brand{
      font-size: 19px !important;
    }
    .col-m-b-20{
      margin-bottom:20px;
    }

    .remove-padding{
      padding:unset !important;
    }
  }