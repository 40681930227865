#cal a:link
{
    color:#000 !important;
}

#cal a:visited
{
    color:#000 !important;
}

#cal a:hover 
{
    color:#000 !important; 
}