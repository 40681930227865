@media only screen and (min-width: 678px) {

    .jumbo-wh{
        height:350px;
        max-Width:95%;
        margin:auto;
        margin-Top:25px;
        margin-bottom: 50px;
        background: url(../assets/teaRoom.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    .jumbo-image{
        max-height:350px;
        width:auto;
    }
    .nav-cont-div{
        padding-top:10px;
    }    
  }

@media only screen and (max-width: 678px) {
    .jumbo-image{
       max-width:100%;
       width:auto;
    }
    .jumbo-wh{
        margin-top: 50px;
    }

    .image-wh{
        max-width:100%
    }
  }

